.impressum {
  font-size: smaller;
  text-align: left;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

a {
  color:inherit;
  text-decoration: none;
}