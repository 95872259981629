:root {
  --body-background-color: #ddd;
  --header-background-color: #1c2237;
  --header-color: white;
  --main-background-color: #fff;
  --main-color: #555;
  --footer-background-color: #1c2237;
  --footer-color: white;
  --site-width: 1024px;
  --accent-color-1: rgb(61, 255, 207);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root {
  width: 100%;
  height: 100%;
}

body {
  overflow-y: scroll;
  background-color: var(--body-background-color);
}


.App {
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: var(--site-width);
  text-align: center;
}


#header {
  background-color: var(--header-background-color);
  color: var(--header-color);
  position:fixed;
  top: 0px;
  max-width: var(--site-width);
  width: 100%;
  text-align: center;
  z-index: 1;
  --padding-left-right: 40px;
  padding-left: var(--padding-left-right);
  padding-right: var(--padding-left-right);
}

header::before, header::after {
  content: '';
  display: block;
  height: 100vw;
  position: absolute;
  top: 0;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

header::before {
  background: linear-gradient(to right,rgba(255,255,255,0),rgba(0,0,0,.1));
  left: -1.2rem;
  width: 1.2rem;
}

header::after {
  background: linear-gradient(to left,rgba(255,255,255,0),rgba(0,0,0,.1));
  right: -1.2rem;
  width: 1.2rem;
}

#main {
  padding-top: 120px;
  padding-bottom: 60px;
  --padding-left-right: 40px;
    padding-left: var(--padding-left-right);
    padding-right: var(--padding-left-right);
  background-color: var(--main-background-color);
  color: #333;
  max-width: var(--site-width);
  text-align: center;
  min-height: 100%;
  line-height: 130%;
}

#footer {
  --padding-top-bottom: 5px;
  padding-top: var(--padding-top-bottom);
  padding-bottom: var(--padding-top-bottom);
  background-color: var(--footer-background-color);
  color: var(--footer-color);
  position: fixed;
  bottom: 0px;
  max-width: var(--site-width);
  width: 100%;
  text-align: center;
  z-index: 1;
  font-size:small;
}