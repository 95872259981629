:root {

}

.logo-container {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  font-size: 2rem;
  color:white;
  font-weight: bold;
  width: 160px;
  height: 50px;
}

.logo-division {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  color:white;
  background-color: var(--accent-color-1);
  bottom: -50px;
  left: 55px;
  width: 50px;
  height: 50px;
  font-weight: bold;
  z-index: 10;
}

.navbar-logo {
  color: #fff;
  align-self: start;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
}