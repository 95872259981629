.nav-item {
  height: 80px;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-links:hover {
  box-shadow:
    0 0,
    0 -4px #fff inset,
    0 0,
    0 0;
  transition: all 0.2s ease-out;
}

.nav-links.active {
  box-shadow:
    0 0,
    0 -4px var(--accent-color-1) inset,
    0 0,
    0 0;
  transition: all 0.2s ease-out;
}

@media screen and (max-width: 958px) {
  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }
}